<template>
  <div>
    <h4>Type Parasite</h4>
        
    <data-table
      :data-source="types"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#add-type-compte"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      title="Ajout type de compte"
      id="add-type-compte"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            type="text"
            id="add-libelle"
            name="add-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="add-code">Code</label>
          <input
            type="text"
            id="add-code"
            name="add-code"
            v-model="code"
            class="form-control"
          >
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Ajouter
            </button>
          </div>
        </div>
      </form>
    </modal>

    <modal
      title="Modification du type de compte"
      id="update-type-compte"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="updating"
      >
        <div class="form-group">
          <label for="update-libelle">Libelle</label>
          <input
            type="text"
            id="update-libelle"
            name="update-libelle"
            v-model="libelle"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label for="update-code">Code</label>
          <input
            type="text"
            id="update-code"
            name="update-code"
            v-model="code"
            class="form-control"
          >
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>
    
    <script>
    import {mapGetters, mapMutations} from 'vuex'
    import DataTable from '../../../components/dataTable/local.vue'
    import Modal from '../../../components/modal.vue'
    import { COMPONENT_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType'
    import {ADD_TYPE_COMPTE, UPDATE_TYPE_COMPTE } from '../../../graphql/user'
    const Action = () => import('../../../components/admin/user/typeCompteAction.vue')
    const NbUser = () => import('../../../components/admin/user/typeCompteUser.vue')
    export default {
        components: { DataTable, Modal },
        data(){
            return {
                libelle: null,
                code: null,
                has_error: false,
                error_msg: null
            }
        },
        watch: {
            selectedObject: {
            handler(){
                if(this.selectedObject !== null) {
                    this.libelle = this.selectedObject.libelle
                    this.code = this.selectedObject.code
                }
            },
            deep: true
        }
        },
        methods: {
            ...mapMutations({
                done: 'DONE',
                setSelectedObject: 'SET_SELECTED_OBJECT'
            }),
            initForm(){
                this.setSelectedObject(null)
                this.libelle = null
                this.code = null
                this.has_error = false
                this.error_msg = null
            },
            adding(){
                let data = {
                    libelle: this.libelle,
                    code: this.code
                }
                this.$apollo.mutate({
                    mutation: ADD_TYPE_COMPTE,
                    variables: {
                        "type": {
                            ...data
                        }
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Type de compte add successfully on uid ${d.addEspece}`)
                    }
                }).then(() => {
                    this.initForm()
                    this.done()
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            },
            updating(){
                let data = {
                    libelle: this.libelle,
                    code: this.code
                }
                this.$apollo.mutate({
                    mutation: UPDATE_TYPE_COMPTE,
                    variables: {
                        "type": {
                            ...data
                        }, 
                        "uid": this.selectedObject.uid
                    },
                    update: () => {
                        console.log(`Type de compte  ${this.selectedObject.libelle} edit successfully on uid ${this.selectedObject.uid}`)
                    }
                }).then(() => {
                    this.initForm()
                    this.done()
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
            
        },
        computed: {
            ...mapGetters({
                types: 'auth/typeComptes',
                selectedObject: 'selectedObject'
            }),
           
            headers(){
                return [
                    {label: 'ID', name: 'uid', type: TEXT_TYPE},
                    {label: 'Libelle', name: 'libelle', type: TEXT_TYPE, sortable: true},
                    {label: 'Code', name: 'code', type: TEXT_TYPE, sortable: true},
                    {label: 'Nombre utilisateur', name: 'nb_user', type: COMPONENT_TYPE, component: NbUser },
                    {label: 'Action', name: 'actoin', type: COMPONENT_TYPE, component: Action}
                ]
            }
        }
    }
    </script>
    
    <style>
    
    </style>